import React from "react";
// import React, { useEffect } from "react";
import CustomAccordion from "shared/Accordion/Accordion";
import "./Background.css";
import AiBanner from "assets/aisg.png";

// import { toast, Toaster, ToastBar } from "react-hot-toast";
// import doApiRequest from "utils/doApiRequest";
// import useApiRequest from "hooks/useApiRequest/useApiRequest";

const ChallengeInformation = () => {
  // ###################### SAMPLE CODE FOR TESTING DOAPIREQUEST FN AND USEAPIREQUEST HOOK - START #################################
  // const testUseApiRequest = useApiRequest("get", "/api/v0.1/result/leaderboard");

  // const testFn = async () => {
  //   const testDoApiRequest = await doApiRequest(
  //     "get",
  //     "/api/v0.1/result/leaderboard"
  //   );
  //   console.log(testDoApiRequest);
  // };

  // const { makeAxiosCall, apiResponseData, apiLoading, apiError } =
  //   useApiRequest("get", "/api/v0.1/result/leaderboard");
  // console.log("makeAxiosCall() called:", apiResponseData, apiLoading, apiError);

  // useEffect(() => {
  //   makeAxiosCall().then(console.log("makeAxiosCall()"));

  //   testFn();
  // }, [makeAxiosCall]);

  // test.then((data) => console.log(data));

  // const promiseToast = async () => {
  //   toast.promise(doApiRequest("get", "/api/v0.1/result/leaderboard"), {
  //     loading: "Fetching data",
  //     success: (data) => {
  //       console.log(data);
  //       return "Data fetched successfully.";
  //     },
  //     error: (error) => {
  //       console.log(error);
  //       return error.message; // Return the error message
  //     },
  //   });
  // };
  // useEffect(() => {
  //   promiseToast();
  // }, []);

  // ###################### SAMPLE CODE FOR TESTING DOAPIREQUEST FN AND USEAPIREQUEST HOOK - END #################################

  return (
    <div>
      <div>
        <div className="banner-overlay">
          <div className="banner-text">
            {/* FYI, div className css file is found in AppNavigator.css */}
            <h1>Global Challenge for Safe and Secure LLMs - Track 2B</h1>
            {/* <p
              style={{
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "2rem",
                marginTop: "20px",
              }}
            >
              <b>Low-Resource Detection of Harmful Memes with Social Bias</b>
            </p> */}
          </div>
        </div>
        <img
          style={{
            width: "100%",
            height: "15.625rem",
          }}
          src={AiBanner}
          alt="aibanner"></img>
      </div>
      <div data-testid="challenge-info-wrapper" className="wrapper">
        <div data-testid="the-title" className="the-title">
          Challenge Information
        </div>

        {/* 1. ABSTRACT */}
        {/* <CustomAccordion
          testId="challenge-info-wrapper-abstract"
          title="Abstract"
        >
          <p>
            Artificial intelligence (AI) and machine learning play a crucial
            role in addressing the pervasive issue of harmful content on social
            media. In multicultural cities like Singapore, the complexity of
            detecting harmful online content arises from the sheer diversity of
            social contexts, languages, colloquialisms, and experiences. This
            Online Safety Prize Challenge (“Challenge”) aims to advance research
            in developing multimodal, multi-lingual, zero-shot models that
            distinguish between benign and harmful memes relevant to the
            Singaporean context. By doing so, AI Singapore (AISG) hopes to spur
            advanced technology development to tackle harmful memes and
            encourage safer online interactions in Singapore and globally.
          </p>
        </CustomAccordion>  */}

        {/* 1. SENSITIVE CONTENT WARNING */}
        <CustomAccordion
          testId="challenge-info-wrapper-sensitive-content-warning"
          title="Sensitive Content Warning">
          <p>
            This Challenge contains materials that some audiences may find
            harmful or offensive. Including such content is essential to meet
            the Challenge's objectives and does not represent the views,
            beliefs, or opinions of AI Singapore (AISG) or the National
            University of Singapore (NUS). The content is strictly for
            informational and educational purposes only. Readers are advised to
            apply discretion in understanding the context of its presentation
            within this Challenge.
          </p>
        </CustomAccordion>

        {/* 3. LIMITATION OF LIABILITY */}
        <CustomAccordion
          testId="challenge-info-wrapper-limitation-of-liability"
          title="Limitation of Liability">
          <p>
            Neither NUS nor AISG shall be held responsible for any harm,
            distress, or offence that participants or any third parties may
            experience due to the content provided in this Challenge.
          </p>
        </CustomAccordion>

        {/* 4. TABLE OF CONTENTS */}
        <CustomAccordion
          testId="challenge-info-wrapper-table-of-contents"
          title="Table of Contents">
          <ul>
            <li>
              <a href="/background" className="url-text-styling">
                Background
              </a>
            </li>
            <li>
              <a href="/challenge-overview" className="url-text-styling">
                Challenge Overview
              </a>
            </li>
            <li>
              <a href="/technical-details" className="url-text-styling">
                Technical Details
              </a>
            </li>
          </ul>
        </CustomAccordion>
      </div>
    </div>
  );
};

export default ChallengeInformation;
